import './App.css';
import { useEffect, useState } from 'react';
//images
import nft1 from "./images/1.png"
import nft2 from "./images/2.png"
import nft3 from "./images/3.png"
import nft4 from "./images/4.png"
import nft5 from "./images/5.png"
import nft6 from "./images/6.png"
import nft7 from "./images/7.png"
import nft8 from "./images/8.png"
import nft9 from "./images/9.png"
import nft10 from "./images/10.png"
import tpaic from "./images/tpaic.png"
import car1 from "./images/car1.gif"
import car2 from "./images/car2.gif"
import car3 from "./images/car3.gif"
import vroom from "./images/vroom.gif"
//fonts
import "./fonts/Adigiana/adigiana.ttf"
import "./fonts/BellyBeans/bellybeans.ttf"
//social icons
import tg from "./images/tg.png"
import tw from "./images/tw.png"
import web from "./images/webb.png"
import os from "./images/os.png"
//styling stuff
import Marquee from 'react-fast-marquee';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//web3 stuff
import { ethers } from 'ethers';
import mintingABI from "./abi/minting.json"
import tokenABI from "./abi/token.json"

function App() {
  return (
    <div className="App">
      <Marquee className='HeaderMarquee' autoFill={true}>
      <span>Welcome to the home of TurboPepe, The AI Valhalla!</span>
        </Marquee>
      <Header/>
      <Hero/>
      <Information/>
      <Marquee className='MintMarquee' autoFill={true} direction='right'>
      <span>$VROOM TO THE MOON</span>
        </Marquee>
      <Mintsection/>
      <Marquee className='MintMarqueeTwo' autoFill={true} direction='left'>
      <span>VROOM RACING</span>
        </Marquee>
        <Racing/>
      <Footer/>
    </div>
  );
}

function Header(){
  return(
  <div id='Header'>
    <h2>Turbo Pepe AI Club</h2>
    <div id='HeaderRight'>
      <Socials/>
      <button onClick={() => window.open("https://linktr.ee/turbopepe")}>All Links</button>
    </div>
  </div>
  )
}

function Hero(){
  return(
  <div id='Hero'>
    <Carousel 
    showThumbs={false}
    width={"300px"}
    swipeable={true}
    showArrows={false}
    showStatus={false}
    className='Carousel'
    >
      <img src={nft1} alt="nft"/>
      <img src={nft10} alt='nft'></img>
      <img src={nft3} alt="nft"/>
      <img src={nft5} alt="nft"/>
      <img src={nft7} alt="nft"/>
    </Carousel>
    <div id='HeroText'>
      <h1>Meet with $VROOM frens!</h1>
      <span>Turbo Pepe AI Club NFTs are 420 unique and AI-generated collectible frogs with Proof of Ownership stored on Ethereum Network.</span>
      <div id='HeroButtons'>
      <button onClick={() => window.location.href = "#Mint"}>Mint Now</button>
      <button onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x73d3a884322af11c6a5e35118c8296cbdad68caf")}>Buy $VROOM</button>
      <button onClick={() => window.location.href = "#Racing"}>Racing</button>
      </div>
      <ToastContainer autoClose={2000} theme='dark'/>
    </div>
  </div>
  )
}

function Information(){
  return(
  <div id='Info'>
    <div id='InfoCell'>
    <div id='InfoText'>
      <span>Welcome to the world of Turbo Pepe AI Club!</span>
      <span>A Web3-born brand with the power of AI, freedom and power of $VROOM.</span>
      <span>The Turbo Pepe AI Club brand produces content, competitions and digital collectibles. We believe in the power of AI and imagination. Therefore we are commited to helping you unlock your inner twin turbo!</span>
      <span>Valhalla might seem far away, but it is closer than you think.</span>
    </div>
    <div id='InfoRight'>
      <h1>TPAIC is LIT</h1>
      <img src={tpaic} alt="tpaic"></img>
    </div>
    </div>
  </div>
  )
}

function Mintsection(){
  const [mintText, setMintText] = useState("Connect Wallet")
  const [amount, setAmount] = useState(1)
  const [totalMinted, setTotalMinted] = useState(0)
  const [isConnected, setisConnected] = useState(false)
  const [paymentType, setPaymentType] = useState("ETH")
  const isMintingEnabled = true

  useEffect(() => {
    getMintInfo()
  },[])
  //ethers init
  const mintingAddress = "0xFbE4bF2A7e6f0d4053073135D1ffe60b3fcDdD63"
  const tokenAddress = "0x73D3A884322AF11C6a5e35118c8296CBdAd68cAF"
  const rpcProvider = "https://rpc.ankr.com/eth"
  //get minting info
  async function getMintInfo(){
    let provider = new ethers.JsonRpcProvider(rpcProvider)
    let mintingContract = new ethers.Contract(mintingAddress, mintingABI, provider)
    const totalMintedBN = await mintingContract.totalSupply()
    setTotalMinted(ethers.formatUnits(totalMintedBN,0))
    document.getElementById("ProgressComplete").style.setProperty("width", 100*(ethers.formatUnits(totalMintedBN,0)/420)+"%")
  }
  //connect wallet
  async function connect(){
    let provider
    if(window.ethereum == null){
      alert("Please Install MetaMask or Use MetaMask Browser")
      provider = ethers.getDefaultProvider()
    }else{
      provider = new ethers.BrowserProvider(window.ethereum)
      let accounts = await provider.getSigner()
      let chain = await provider.getNetwork()
      if(chain.chainId !== 1n){
        alert("Please Connect to the Ethereum Mainnet!")
        window.location.reload()
      }else{
        toast(`Connected with ${accounts.address}`)
        setMintText("Mint Now")
        setisConnected(true)
      }
    }
  }
  //mint functions
  async function mint(amount){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    let mintPrice = ethers.parseUnits(`${0.01*amount}`, 18)
    const mintingContract = new ethers.Contract(mintingAddress,mintingABI,signer)
    const id = toast.loading(`Minting ${amount} NFT(s)...`)
    const mint = await mintingContract.mintTPAIC(amount, {value: mintPrice})
    .then(async res => {
      await res.wait()
      toast.update(id, {render:`Successfully Minted ${amount} NFT(s)`, type:"success", autoClose:2000, isLoading:false})
      getMintInfo()
    })
    .catch(ex => {
      console.log(ex)
      toast.update(id,{render:`${ex.code}`, type:"error", autoClose:2000, isLoading:false})
    })
    
  }
  async function mintWithTokens(amount){
    let provider = new ethers.BrowserProvider(window.ethereum)
    let signer = await provider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddress,tokenABI,signer)
    const mintingContract = new ethers.Contract(mintingAddress,mintingABI,signer)
    const allowanceBN = await tokenContract.allowance(signer.address,mintingAddress)
    const allowance = Number(ethers.formatUnits(allowanceBN, 18))
    //approve
    const approving = toast.loading(`Approving...`)
    const approve = await tokenContract.approve(mintingAddress,ethers.parseEther(`${amount*100000000}`,18))
    .then(async res => {
      await res.wait()
      toast.update(approving, {render:"Approved Successfully", type:"success", autoClose:2000, isLoading:false})
      //mint
      const minting = toast.loading(`Minting ${amount} NFT(s)...`)
      const mint = await mintingContract.mintTPAICusingVROOM(amount)
      .then(async result => {
        await result.wait()
        toast.update(minting, {render:"Minted Successfully", type:"success", autoClose:2000, isLoading:false})
      })
      .catch(ex =>{
        toast.update(minting, {render:ex.code, type:"error", autoClose:2000, isLoading:false})
      })
    })
    .catch(err => {
      toast.update(approving, {render:err.code, type:"error", autoClose:2000, isLoading:false})
      return
    })
    
  getMintInfo()
  }
  //handle amount change
  function handleAmount(change){
    if(change === -1){
      if(amount > 1) setAmount(amount-1)
    }else{
      if(amount<20) setAmount(amount+1)
    }
  }
  //change currency
  function changeCurrency(){
    if(paymentType === "ETH"){
      setPaymentType("VROOM")
    }else{
      setPaymentType("ETH")
    }
  }
  //XHTML
  return(
    <div id='Mint'>
      <Marquee className='MintMarquee2' autoFill={true}>
        <img src={nft2} alt='nft'></img>
        <img src={nft4} alt='nft'></img>
        <img src={nft6} alt='nft'></img>
        <img src={nft8} alt='nft'></img>
        <img src={nft10} alt='nft'></img>
        <img src={nft9} alt='nft'></img>
        <img src={nft3} alt='nft'></img>
      </Marquee>
      {
        isMintingEnabled ? 
        <div id='MintRight'>
        <h1>Mint Now!</h1>
        <span>Join the ride with TurboPepe!</span>
        <div id='ProgressBar'>
          <div id='ProgressComplete'></div>
        </div>
        <span>Total Mints: {totalMinted}/420</span>
        <span>Total Mint Price</span>
        {
          paymentType === "ETH" ?
          <span>{amount*0.01 + " $ETH"}</span>
          :
          <span>{(amount*100000000).toLocaleString("en-US") + " $VROOM"}</span>
        }
        <div id='MintCell'>
          <button onClick={() => handleAmount(-1)}>-</button>
          <span>{amount}</span>
          <button onClick={() => handleAmount(1)}>+</button>
        </div>
        <div id='MintButtonContainer'>
        <button onClick={() => {
          if(!isConnected){
            connect()
          }else{
            if(paymentType === "ETH"){
              mint(amount)
            }else{
              mintWithTokens(amount)
            }
            
          }
          }}>{mintText}</button>
        <button onClick={() => changeCurrency()} className='Currency'>{"$"+paymentType}</button>
        </div>
        <span style={{width:"60%", justifySelf:"center"}}>Click on $ETH or $VROOM button to switch between payment methods.</span>
      </div>
        :
        <div id='MintRight'>
        <h1>Minting SOON!</h1>
        <span>Join the ride with TurboPepe!</span>
        <div style={{display:"none"}} id='ProgressBar'>
          <div id='ProgressComplete'></div>
        </div>
        <span>Mint Price: 0.01 ETH</span>
        <button>Minting Soon!</button>
      </div>
      }
      
    </div>
  )
}

function Racing(){
  return(
    <div id="Racing">
      <h1>Pedal to the Metal!</h1>
      <div id='Cars'>
        <img src={vroom} id='VROOM' alt="Vroom"/>
        <img src={car1} id='Car1' alt="Car"/>
        <img src={car2} id='Car2' alt="Car"/>
        <img src={car3} id='Car3' alt="Car"/>
      </div>
      <div id='RacingExplanation'>
        <span>Here is the ultimate racing GameFi experience that will<br/>make you feel the adrenaline through your bones!</span>
        <span>If you have a TPAIC NFT, you will be able to<br/>join races and earn more $VROOM!</span>
        <span>We also invite $PEPE, $TURBO, $MONG and many more communities to the race and make them earn their native tokens!</span>
        <span>To play the game or learn more about the VROOM Racing dApp,<br/>click "Go to Racing" button now!</span>
      </div>
      <div id='RacingButtons'>
        <button onClick={() => window.open("https://racing.turbopepe.net")}>Go to Racing</button>
        <button onClick={() => window.location.href = "#Mint"}>Mint Your TPAIC</button>
        <button onClick={() => window.open("https://opensea.io/collection/tpaic")}>Buy Your TPAIC</button>
      </div>
      </div>
  )
}

function Socials(){
  return(
    <div id='Socials'>
      <a href='https://opensea.io/collection/tpaic'><img src={os} alt='social'/></a>
      <a href='https://turbopepe.net'><img src={web} alt='social'/></a>
      <a href='https://twitter.com/TurboPepeAIC'><img src={tw} alt='social'/></a>
      <a href='https://t.me/turbopepeofficial'><img src={tg} alt='social'/></a>
    </div>
  )
}

function Footer(){
  return(
    <div id='Footer'>
      <span>Turbo Pepe AI Club - 2023</span>
      <span>Made by TurboPepe - $VROOM Team</span>
      <Socials/>
    </div>
  )
}

export default App;
